import { FC, useCallback } from "react";
import { Button } from "components/Button";
import AuthPageShell from "components/Login/AuthPageShell";
import EmailPasswordForm, {
  FormDataType,
} from "components/Login/EmailPasswordForm";
import LoginRedirect from "components/Login/LoginRedirect";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import analytics from "@lib/analytics";
import {
  CoachAndContactServerSideProps,
  getCoachAndContactServerSideProps as getServerSideProps,
} from "@lib/utils/server-side-props";

import { useAuthErrorState } from "@components/Client/SignUpUnifiedOnboardingForm";
import GoogleAccountIcon from "@components/Icons/GoogleAccountIcon";
import ResetPopup from "@components/Login/ResetPopup";

const ClientLoginPage: FC<CoachAndContactServerSideProps> = ({
  coach,
  contact,
  contactEmail,
}) => {
  const { signin } = useAuth();
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useAuthErrorState();
  const { requestedPage, prefill, contactId } = router.query;

  const onGoogleSubmit = useCallback(async () => {
    analytics.track({
      event: "sign_up_click",
      properties: { method: "web", kind: "google" },
    });

    window.location.href = `/api/gauth/auth-init?view=login&scopes=authorize`;
  }, []);

  const onSubmit = async (data: FormDataType) => {
    try {
      const { email, password } = data;
      await signin(email.toLowerCase().trim(), password);
      analytics.track("client_login_success");
    } catch (e) {
      analytics.track("client_login_error");
      if (e.code === "auth/wrong-password") {
        setErrorMessage("You entered a wrong password. Please try again.");
      }
      if (e.code === "auth/user-not-found") {
        setErrorMessage(
          "User not found. Please create an account if you have not already."
        );
      }
    }
  };

  return (
    <LoginRedirect
      coach={coach}
      contactEmail={contactEmail || contact?.email}
      requestedPage={requestedPage as string}
    >
      <AuthPageShell
        metaTitle="Login"
        coach={coach}
        title="Sign in to your shared workspace"
        content={
          <>
            <ResetPopup coachSlug={coach?.slug} />
            <EmailPasswordForm
              defaultValues={{
                email:
                  contactEmail || contact?.email || (prefill as string) || "",
                password: "",
              }}
              onSubmit={onSubmit}
              onSubmitButtonText="Sign in"
              errorMessage={errorMessage}
              resetPasswordHref={`/me/${coach?.slug}/reset-password`}
            />
            <Button
              className="w-full mt-2"
              variant="secondary-theme"
              onClick={() =>
                router.push({
                  pathname: `/me/${coach?.slug}/create-account`,
                  query: {
                    ...(requestedPage && { requestedPage }),
                    ...(contactId && { contactId }),
                  },
                })
              }
              data-heap-event-name="client_login_page_create_account_button_clicked"
            >
              Create an account
            </Button>
            <Button
              className="w-full mt-2"
              type="button"
              icon={<GoogleAccountIcon className="w-6 h-6" />}
              onClick={onGoogleSubmit}
              data-heap-event-name="client_login_page_google_sign_in_button_clicked"
            >
              Continue with Google
            </Button>
          </>
        }
      />
    </LoginRedirect>
  );
};

export { getServerSideProps };

export default ClientLoginPage;
